import 'babel-polyfill';
import 'bootstrap';
import SmoothScroll from 'smooth-scroll'

import './styles/boostrap.scss';
import './styles/fonts.scss';
import './styles/style.scss';
import './styles/sliders.scss';
import './styles/menu.scss';
import './styles/footer.scss';
import './styles/inputs.scss';

import "./js/menu";
import "./js/icons";
import "./js/sliders";
import "./js/inputs";

var scroll = new SmoothScroll('a[href*="#"]');