import $ from "jquery";

$(".open-menu").on("click", function(event){
  event.preventDefault();
  $("#menu-principal").toggleClass("d-none");
  $("#menu-principal").toggleClass("d-menu-mobile");
});

$('.dropdown-sub-menu').hover(function() {
  var men = $(this),
      box = men.closest(".dropdown-menu"),
      sub = men.find(".sub-menu"),
      wid = Math.ceil(sub.width()),
      hei = Math.ceil(box.height()) + 8;
  sub.css({"right": "-"+wid+"px", "height":hei+"px"});
})

$("body").on("click",".close-menu",function(event) {
  event.preventDefault();
  $("#options-menu").toggleClass("d-none");
});

$("body").on("click",".close-form",function(event) {
  event.preventDefault();
  $("#menu-login").toggleClass("d-none");
})

window.addEventListener("scroll", bringmenu);
var lastScrollTop = 0;

function bringmenu() {

  var element = document.getElementById("main-menu");

  if (document.documentElement.scrollTop > 0) {

    if(lastScrollTop < document.documentElement.scrollTop){
      element.style.top = "-200px";
    }else{
      element.style.top = "0";
      element.classList.add("scroll");
    }

  } else {
    element.style.top = "0";
    element.classList.remove("scroll");

  }

  lastScrollTop = document.documentElement.scrollTop;

}

$("body").on("click",".toggle-v-mas",function(){
  var ele = $(this);
  ele.html(ele.html()=="VER MÁS" ? "VER MENOS":"VER MÁS");
  var menu = ele.closest(".item-soluciones").find("ul");
  if(menu.hasClass("v-mas")){
    menu.removeClass("v-mas");
  }else{
    menu.addClass("v-mas");
  }
});

// modal
// when the modal is opened autoplay it  
$('#ver-video').on('shown.bs.modal', function (e) {
  var videoSrc = $("#btn-ver-video").attr("data-src");
  // set the video src to autoplay and not to show related video. Youtube related video is like a box of chocolates... you never know what you're gonna get
  $("#video").attr('src',videoSrc + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0" ); 
})
    
  
  
  // stop playing the youtube video when I close the modal
  $('#ver-video').on('hide.bs.modal', function (e) {
    // a poor man's stop video
    $("#video").attr('src',''); 
  }) 
      