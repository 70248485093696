import Splide from '@splidejs/splide';

// slider de 4 items
let sliderx4 = document.getElementsByClassName("sliderx4");
for ( let i = 0, len = sliderx4.length; i < len; i++ ) {

  new Splide( sliderx4[ i ], {
    perPage: 4,
    type   : 'loop',
    pagination: false,
    autoplay: true,
    lazyLoad: 'sequential',
    breakpoints: {
      1200: {
        perPage: 4,
      },
      960: {
        perPage: 3,
      },
      765: {
        perPage: 2,
      },
      640: {
        perPage: 1
      }
    }
  } ).mount();
  
}


// slider de 5 items
let sliderx5 = document.getElementsByClassName("sliderx5");
for ( let i = 0, len = sliderx5.length; i < len; i++ ) {

  new Splide( sliderx5[ i ], {
    perPage: 5,
    type   : 'loop',
    autoplay: true,
    pagination: false,
    lazyLoad: 'sequential',
    breakpoints: {
      1200: {
        perPage: 5,
      },
      960: {
        perPage: 4,
      },
      765: {
        perPage: 2,
      },
      640: {
        perPage: 1
      }
    }
  } ).mount();
  
}



// Carousel del producto
let productDetails = document.getElementsByClassName("product-details");
for ( let i = 0, len = productDetails.length; i < len; i++ ) {

  let slideProduct = new Splide( productDetails[ i ], {
    arrows: false,
    autoplay: false,
    pagination : false,
    lazyLoad: 'sequential'
  } ).mount();

}